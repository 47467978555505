import { useState } from "react";

export interface AgentResponse {
    message : AgentMessage[],
    session_id : string,
}

export interface AgentMessage {
    content : string,
    name : string,
    role : string,
    timestamp : number
}

const dev_kubi_url = "https://jhfuyl41wj.execute-api.us-east-1.amazonaws.com/dev/chat";

export interface KubiChatRequest {
    message: string;
    knowledgeBaseId: string;// | KubiAssistants[];
    sessionId?: string;
}


export interface ChatMessage {
    message: string;
    role: string;
}

export function useKubi(knowledgeBaseId: string, session_Id?: string)
{
    const [messages, setMessages] = useState<ChatMessage[]>([]);
    const [loading, setLoading] = useState(false);
    const [sessionId, setSessionId] = useState<string | undefined>(session_Id);


    const processResponseFromKubi = async (userMessage: string, newMessages : ChatMessage[]) =>
    {
        setLoading(true);
        const response = await fetch(dev_kubi_url, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                message: userMessage,
                knowledgeBaseId: knowledgeBaseId,
                sessionId: sessionId,
            }),
        });


        
        console.log(response);

        const data : AgentResponse = await response.json();

        console.log(data);

        if(!sessionId){
            setSessionId(data.session_id);
        }

        const flattenedNewMessages = data.message.map((agentResponse) => {
            return {
                message: agentResponse.content,
                role: "agent",
            };
        });

        setMessages([...newMessages, ...flattenedNewMessages]);
        setLoading(false);
    }

    return {
        messages : messages,
        addUserMessage(message: string) {
            const newMessages = [...messages, { message: message, role: "user" }];

            setMessages(newMessages);

            processResponseFromKubi(message, newMessages);
        },
        loading: loading,
    }
}