import React from "react";
import ChatContent from "./ChatContent";
import ChatInputBox from "./ChatInputBox";
import { useKubi } from "../useKubi";
import { VscSparkle } from "react-icons/vsc";

export default function Chat(props: { assistantId: string }) {

  /** State to control new messages */
  const { messages, addUserMessage, loading } = useKubi(props.assistantId);

  return (
    <div className='bg-transparent h-full w-full flex items-center justify-center'>
      <div className="relative flex flex-col w-full max-w-2xl p-1">
        <ChatContent messages={messages} addUserMessage={addUserMessage} />
        <ChatInputBox sendANewMessage={addUserMessage} />
        <div className="text-xs flex flex-row items-center py-1 text-gray-500 w-full justify-end ">
          <a href="https://kubi.ai" className="bg-gray-100 px-1 rounded-md flex flex-row items-center ">
            Kubi Assistant <VscSparkle className="pl-0.5"/>
          </a>
        </div>
      </div>
    </div>
  );
};
