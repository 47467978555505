import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Chat from './components/ChatApp';

const root = ReactDOM.createRoot(
  document.getElementById('kubi-chat') as HTMLElement
);

//extract the assistantId from the data-kubi-assistant-id attribute
const assistantId = document.getElementById('kubi-chat')?.getAttribute('data-assistant-id') || "";

root.render(
  <React.StrictMode>
    <Chat  assistantId={assistantId}/>
  </React.StrictMode>
);
