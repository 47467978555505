import { useState } from "react";
import { VscInfo } from "react-icons/vsc";


const ChatInputBox = (props: { sendANewMessage : (message: string) => void }) => {

  const doSendMessage = (newMessage : string) => {
    if (newMessage && newMessage.length > 0) props.sendANewMessage(newMessage);
  };

  return (
    <div className="overflow-hidden flex-grow-0">
      <div className="flex flex-row items-center">
        <DebouncedInput debounce={100} onSend={doSendMessage} />
      </div>
    </div>
  );
};

/**
 *
 * @param param0
 * Debounced Input, should handle debouncing in order to avoid
 * too many state changes
 */

function DebouncedInput(props: { onSend: (message : string) => void, debounce?: number })
{
  const [value, setValue] = useState<string>("");

  const onSendInternal = () => {
    props.onSend(value);
    setValue("");
  }

  return (
    <div className="relative w-full">
      <input
        type="text"
        className=" block w-full rounded-md shadow-inner border-none placeholder:text-neutral-400 placeholder:font-normal bg-gray-50 font-medium text-neutral-500 appearance-none focus:outline-none focus:ring-0 focus:ring-transparent"
        id="message-box"
        placeholder="Ask me anything about Arkansas Urology!"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={(e) => {
            if (e.key === "Enter") {
              onSendInternal();
            }
          }
        }
      />
      <div className=" hover:bg-gray-300 filter drop-shadow text-white bg-pink-500 absolute inset-y-0 right-0 flex items-center justify-center p-1 px-2 cursor-pointer  m-1 rounded" onClick={onSendInternal}>
        <VscInfo className="w-5 h-5" />
      </div>
    </div>
  );
};


export default ChatInputBox;
