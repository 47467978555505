import { ChatMessage } from "../useKubi";
import { ThreeDots } from 'react-loading-icons'

export default function ChatContent(props : { messages : ChatMessage[], addUserMessage : (message : string) => void }) {

  const message_components = props.messages.map((message : ChatMessage , index: number) => <ChatMessageComponent message={message} key={index} />);

  const last_message_is_user = props.messages.length > 0 && props.messages[props.messages.length - 1].role === "user";

  return (
    <div className=" py-1 overflow-y-scroll flex-grow flex flex-col">
      {message_components}
      {last_message_is_user ? <KubiIsTypingTombstone /> : null}
    </div>
  );
};

function ChatMessageComponent(props: { message: ChatMessage }) {
  const isChatOwner = props.message.role === "user";

  return(
    <div className={`flex flex-row w-full ${ isChatOwner ? "justify-end" : "justify-start" }`}>
      <div className="p-1">
        <div className={`px-1 w-fit flex flex-col rounded-lg ${ isChatOwner ? "bg-gray-100 order-1 text-gray-700 " : "bg-pink-500 order-2 text-white" }`}>
          <span className="text-sm my-1">{props.message.message}</span>
        </div>
        <span className="text-xs gap-1 flex p-0.5">
            <span className="text-gray-500 ">{isChatOwner ? "You" : "Arkansas Urology Assistant"}</span>
            <span className="text-gray-400">{new Date().toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" })}</span>
          </span>
      </div>
    </div>
  );
}

function KubiIsTypingTombstone() {
  return (
    <div className="flex flex-row w-full justify-start">
      <div className="p-1">
        <div className=" rounded-lg bg-pink-400 text-white px-2 py-1">
          <ThreeDots className="h-4 w-8" speed={.50} />
        </div>
      </div>
    </div>
  );
}


/*

          
          */